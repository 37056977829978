var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "govuk-radios__item" },
    [
      _c("input", {
        staticClass: "govuk-radios__input",
        attrs: {
          id: _vm.id,
          name: _vm.name,
          type: "radio",
          disabled: _vm.disabled
        },
        domProps: { checked: _vm.bindTo === _vm.value, value: _vm.value },
        on: {
          change: function($event) {
            return _vm.$emit("input", _vm.value)
          }
        }
      }),
      _c(
        "gov-label",
        { staticClass: "govuk-radios__label", attrs: { for: _vm.id } },
        [_vm._v(_vm._s(_vm.label))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }